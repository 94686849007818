import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { borderStroke, colorTokens, ContentContainerAppClient, PageTitle, useTranslate } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpcReact } from '../../client'
import { TableCellLabel } from '../molecules/table-elements/table-cell-label'
import { TableCellText } from '../molecules/table-elements/table-cell-text'

export default memo(function AccountPage() {
  const translate = useTranslate()
  const [user] = trpcReact.users.loadMyInformation.useSuspenseQuery()
  return (
    <>
      <PageTitle title={translate('my_account')} />
      <ContentContainerAppClient>
        <TableContainer css={{ border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base']}` }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>{translate('profile')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellLabel text={translate('dimension_name_last_name')} />
                <TableCell>{user.lastName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCellLabel text={translate('dimension_name_first_name')} />
                <TableCell>{user.firstName}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer css={{ border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base']}` }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>{translate('contact_information')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellLabel text={translate('dimension_name_email')} />
                <TableCellText text={'email' in user ? user.email : user.unverifiedEmail} />
              </TableRow>
              <TableRow>
                <TableCellLabel text={translate('dimension_name_phone')} />
                <TableCellText text={user.phone} />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ContentContainerAppClient>
    </>
  )
})
